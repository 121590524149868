.status-panel {
  max-width: 20rem;
  height: 100vh;
  padding: 3rem 2rem;
  background-color: var(--xpub-secondary-color);

  // .readiness-index {
  //   display: flex;
  //   text-align: center;
  //   margin-bottom: 3rem;
  //   gap: 1rem;
  //   align-items: center;

  //   p {
  //     font-size: var(--text-medium);
  //     margin: 0;
  //   }

  //   h2 {
  //     width: 4rem;
  //     height: 4rem;
  //     border-radius: 50%;
  //     background-color: yellow;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     font-size: 1.5em;
  //     font-weight: bold;
  //   }
  // }

  .steps-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .step-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      &.active {
        border: 1px solid black;
        border-radius: var(--border-radius);
        
        background-color: var(--contrast-color-secondary);
      }

      label {
        width: 90%;
        font-size: var(--text-medium);
        cursor: pointer;
      }

      .circle-icon {
        width: 1.15rem;
        height: 1.15rem;
        border: 1px solid #000;
        border-radius: 50%;
      }
    }
  }
}
