.right-panel {
  padding: 3rem;

  .page-title {
    font-size: var(--text-extra-large);
  }

  .tiles-container {
    display: flex;
    gap: 10px;
    margin-bottom: 1rem;

    .tile {
      flex: 1;
      display: flex;
      flex-direction: row !important;
      padding: 0.5rem;
      // background-color: var(--xpub-brand-color);
      // border: 1px solid var(--xpub-brand-color);
      border-radius: var(--border-radius);
      // background-color: white;
      background-color: var(--xpub-secondary-color);
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      gap: 0.5rem;
      color: var(--text-color-secondary);
      width: 13rem !important;
      font-size: var(--text-medium);
    }
  }

  .main-section {
    background-color: #fff;
    height: 60vh;
    overflow-y: auto;
    border-radius: var(--border-radius);
    border: 1px solid #ddd;
    margin-bottom: 20px;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 10px;
        padding: 0 20px;
        position: relative;

        &::before {
          content: "•";
          position: absolute;
          left: 0;
          color: #000;
        }
      }
    }
  }

  .mark-reviewed {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    padding: 10px 0;
    position: absolute;
    right: 3rem;

    .bx--radio-button-group {
      display: flex;
      align-items: center;
    }

    .bx--radio-button {
      margin-left: 10px;
    }
  }
}

.table-container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.assigned-to {
  margin-left: auto;
  font-weight: 600;
}

.accordion-content p {
  margin-bottom: 1rem;
}

.accordion-content .bx--checkbox {
  margin-top: 1rem;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--text-medium);
  font-weight: 500;
}

.accordion-content {
  padding-left: 2rem;
}

.list-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.list-item span {
  margin-left: 8px;
  font-size: var(--text-medium);
}

.accordion-content ul.no-bullets li::before {
  content: none;
}

.right-panel .main-section ul li::before {
  content: none;
}

.accordion-content li.list-item {
  margin-bottom: 1rem;
}
