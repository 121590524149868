/* Example styles for the clickable tiles */
.tile-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* Adjust the gap between tiles as needed */
}

.factcheck-container {
  border-top: 2px solid var(--contrast-color-secondary);
  border-left: 2px solid var(--contrast-color-secondary);
  padding: 1rem 1rem;
  margin: 0rem;
  height: 100%;
}

.back-to-editor {
  display: "flex";
  flex-direction: row;
  align-items: "center";
  padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  cursor: "pointer";
  color: var(--text-color-secondary);
}