.clickable-tile {
  cursor: pointer;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: lightgray;
    /* Change the background color on hover */
  }
}

.separator {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}
