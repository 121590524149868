.audio-control {
  margin-top: 0.2rem;
  width: 100%;
  height: 9.5rem;
  padding: 2% 2%;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
}

.menubar {
  width: 100%;
  display: flex;
  margin: 0 0 1.5% 0;
  justify-content: space-between;
}

.audio-file-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  margin-right: 5%;
}

.control {
  display: flex;
  gap: 10px;
}

.confidence-control {
  display: flex;
  gap: 40px;
}

.audio-player {
  width: 100%;
}

.react-audio-player {
  width: 100%;
}

.filename {
  font-size: var(--text-medium);
  text-align: left;
  align-self: flex-start;
}

.infoText {
  font-size: var(--text-small);
  align-self: flex-start;
}

.custom-audio-player {
  width: 100%;
  border-radius: var(--border-radius);
  height: 2.5rem !important;
}

audio {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

audio::-webkit-media-controls-enclosure {
  background-color: #d8d2e0;
  border-radius: var(--border-radius);
}
