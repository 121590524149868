.custom-menu-container {
  position: relative;
  display: inline-block;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  /* Adjust the width as needed */
  padding: 8px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-bottom: none;
  /* Remove bottom border */
  border-radius: 4px 4px 0 0;
}

.menu-title {
  flex-grow: 1;
  /* Expand to fill available space */
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  font-size: var(--text-medium);
}

.close-button {
  font-weight: bold;
  color: #000;
  cursor: pointer;
}

.menu-items {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 0;
  box-sizing: border-box;
}

.action-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  font-size: var(--text-medium);
  cursor: pointer;

  &.disabled {
    color: lightgray;
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
      background-color: inherit;
      color: lightgray;
      cursor: not-allowed;
    }
  }

  &.submenu-item {
    background-color: f5f5f5;
    padding-left: 20px;
  }

  &:hover:not(.disabled) {
    background-color: #e0e0e0;
  }
}
