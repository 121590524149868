.layout-container {
  display: flex;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.main-content {
  overflow-y: auto;
  transition: margin-left 0.3s ease, width 0.3s ease;
  padding: 0;
}
