.tags-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  margin-top: 1%;
  border-radius: var(--border-radius);
  background-color: var(--xpub-secondary-color);

  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.tag-item {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: var(--border-radius);
  padding: 5px 10px;
  font-size: var(--text-medium);
}

.tag-item .text {
  margin-right: 10px;
  font-size: var(--text-medium);
}

.tag-item .close {
  cursor: pointer;
}

.tag-input {
  border: none;
  outline: none;
  flex-grow: 1;
  background-color: var(--xpub-secondary-color) !important;
  font-size: var(--text-medium);
}

.label-multiple-url {
  font-size: var(--text-small);
  color: #525252;
}

.label-multiple-url-heading {
  font-size: var(--text-medium);
  color: #525252;
}
