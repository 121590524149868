.side-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // background-color: var(--contrast-color-primary);
  // border-right: 1px solid var(--xpub-brand-color-border);
  background-color: var(--xpub-secondary-color);
  width: 100%;
  padding: 0.5rem 0.5rem;
}

.files-section {
  height: 45vh;
  padding-left: 1rem;
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-file-button {
  display: flex;
  transition: background-color 0.3s;
  color: var(--button-primary);
  font-size: var(--text-medium);
  border: 2px solid var(--button-primary);
  padding: 0.3rem;
  background-color: #f7fdfd;
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1rem;

  &:hover {
    background-color: var(--button-primary-hover);
    color: #f7fdfd;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.actions-section {
  margin-top: 2rem;
  padding: 0 1rem;
}

.actions-section-title {
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: var(--text-large);
  color: var(--text-color-secondary);
}

.action-button {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--button-primary);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s;
  color: var(--button-primary-text-color);
  font-size: var(--text-medium);

  &:hover {
    background-color: var(--button-primary-hover); /* Hover effect */
  }

  &:active {
    background-color: var(--button-primary-active); /* Active effect */
  }

  svg {
    margin-right: 0.5rem; /* Space between icon and text */
  }
}
