.ql-container.ql-snow {
  // height: calc(100vh - 9rem);
  background: rgb(212, 212, 212);
  justify-content: center;
  flex: 1 0 auto;
  display: flex;
  overflow: hidden;
  border: none;
  font-size: inherit;
  // box-shadow: 20px 0 20px -10px lightgrey, -20px 0 20px -10px lightgrey;
  /* add this line */
  background-color: inherit;
  &.notification {
    height: calc(100vh - 100rem) !important;
  }
  border-radius: 0.2rem;
}

.ql-toolbar.ql-snow {
  background: rgb(212, 212, 212);
}
