.input-container {
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;
}

.keywords-buttons {
  // margin-left: 10px;
  font-size: inherit;
  // background-color: var(--xpub-brand-color);
  // color: #fff;
  border-radius: var(--border-radius);
  cursor: pointer;
  // outline: none;

  // &:hover {
  //   background-color: #5a3887;
  // }
}

.keywords-buttons.generate-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px 8px 15px;
  width: "100%";
  // cursor: not-allowed;
  background-color: #ccc;
  color: black;
}

.keywords-buttons.add-keywords-button {
  border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
  margin-left: 0;
  padding: 0rem 1rem;
  min-height: 0;
  align-items: center;
}

.keyword-input {
  .cds--text-input {
    border-radius: var(--border-radius) 0 0 var(--border-radius) !important;
    // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  }
}

.lock {
  margin-left: 10px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 0.1rem dashed var(--xpub-brand-color);
  border-radius: var(--border-radius);
  padding: 1rem;
  margin-bottom: 1rem;
}

.tag-item {
  display: inline-flex;
  align-items: center;
  border-radius: var(--border-radius);
  padding: 5px 10px;
  font-size: var(--text-medium);
  margin-bottom: 5px;
}

.tag-item .text {
  margin-right: 10px;
  font-size: var(--text-medium);
}

.tag-item .close {
  cursor: pointer;
}
