.text_input {
  margin-top: 2%;
  // border-block-end: 0rem !important;
  background-color: var(--xpub-secondary-color);
}

.file-tile {
  padding: 0.5rem 1.5rem;
  margin: 0.5rem 0rem;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  border-radius: var(--border-radius);
  transition: background-color 0.3s;
  min-height: 3.2rem;
}

.selected-file-tile {
  background-color: #e5b3ea;
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-info p {
  width: 70%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cds--toggle__switch--checked {
  background-color: var(--button-primary) !important;
}

.cds--toggle__button:focus + .cds--toggle__label .cds--toggle__switch,
.cds--toggle:not(.cds--toggle--disabled):active .cds--toggle__switch {
  box-shadow: none !important;
}

.idea-modal .cds--modal-content {
  padding-block-end: 2rem !important;
}
