.sales-button-panel {
  margin-top: 2%;
}

.deck-label {
  font-size: var(--text-large);
  font-weight: 750;
  text-align: left;
  align-self: flex-start;
}
