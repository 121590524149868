@use "@carbon/react/scss/themes";

@use "@carbon/react/scss/theme" with (
  $theme: (
    border-interactive: var(--border-interactive),
    focus: var(--focus),
    interactive: var(--interactive),
    interactive-01: var(--interactive-01),
    interactive-02: var(--interactive-02),
    button-primary: var(--button-primary),
    button-primary-active: var(--button-primary-active),
    button-primary-hover: var(--button-primary-hover),
    text-on-color: var(--button-primary-text-color),
    button-tertiary: var(--button-tertiary),
    button-tertiary-hover: var(--button-tertiary-hover),
    // support-success: var(--cds-support-success),
    // button-tertiary-active: var(--button-tertiary-active),,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
  )
);

:root {
  --xpub-brand-color: #5a0b4d;
  --xpub-brand-color-border: #44083a;
  // --contrast-color-primary: #44083a;

  --xpub-secondary-color: #f4f4f4;
  // --xpub-secondary-color: white;

  // --xpub-secondary-color: #eeeeee;

  --contrast-color-secondary: #e4e4e4;

  --xpub-accent-color-1: #ee4266;
  --xpub-accent-color-2: #55828b;

  --element-hover-color: #9f1388;

  --text-color-primary: #fce8f9;
  --text-color-secondary: #170313;

  --contrast-color-primary: #e5dada;

  --text-extra-large: 1.25rem;
  --text-large: 1rem;
  --text-medium: 0.875rem;
  --text-small: 0.75rem;
  --text-xsmall: 0.7rem;
  --text-xxsmall: 0.65rem;
  --border-radius: 0.5rem;

  --border-interactive: #0e393f;
  --focus: #0e393f;
  --interactive: #0e393f;
  --interactive-01: #0e393f;
  --interactive-02: #0e393f;

  --button-primary: #55828b;
  --button-primary-active: #44686f;
  --button-primary-hover: #719fa8;
  --button-primary-text-color: #eff4f5;
  --text-color-primary: #eff4f5;

  --button-tertiary: #55828b;
  --button-tertiary-hover: #44686f;
  --button-tertiary-active: #719fa8;

  // --button-primary: #4c9a78;
  // --button-primary-active: #3b785d;
  // --button-primary-hover: #65b391;
  // --button-primary-text-color: #eef7f3;

  // --button-tertiary: #4c9a78;
  // --button-tertiary-hover: #3b785d;
  // --button-tertiary-active: #65b391;

  @include theme.theme();
  // --cds-btn-primary-background-color: var(--xpub-brand-color) !important;
  --cds-support-success: var(--button-primary) !important;
  // --cds-button-primary: var(--button-primary) !important;
}

.font-sans {
  font-family: "Montserrat", "IBM Plex Sans", sans-serif;
  font-weight: 500;
}

.cds--loading__stroke {
  stroke: var(--xpub-brand-color);
}

.cds--tabs .cds--tabs__nav-item--selected {
  border-block-end-color: var(--button-primary) !important;
}

.cds--progress-step--current .cds--progress-line,
.cds--progress-step--complete .cds--progress-line {
  background-color: var(--xpub-brand-color) !important;
}

.cds--batch-summary,
.cds--batch-actions {
  background-color: var(--button-primary) !important;
  border-radius: var(--border-radius) var(--border-radius) 0rem 0rem !important;
}

.cds--progress-step svg {
  fill: var(--xpub-brand-color) !important;
}

.cds--progress-step {
  min-inline-size: 10rem;
}

.cds--progress-line {
  inline-size: 10rem;
}

.cds--progress-label {
  max-inline-size: 10rem;
}

.cds--inline-notification {
  max-inline-size: 200rem !important;
  // flex-grow: 1 !important;
}

.cds--modal-container,
.cds--btn--primary,
.cds--btn--danger,
.cds--btn--secondary,
.cds--btn-tertiary,
.cds--toolbar-content .cds--search .cds--search-input,
.cds--text-area,
.cds--text-input,
.cds--dropdown,
.cds--dropdown .cds--list-box__field {
  border-radius: var(--border-radius) !important;
  border-block-end: 0rem !important;
  // border: 1px solid var(--xpub-brand-color) !important;
}

.cs--modal.cds--list-box__menu {
  box-shadow: 0px;
}

.cds--skeleton__placeholder::before {
  border-radius: var(--border-radius) !important;
}

.thumbnail .cds--text-area {
  border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
  outline: none !important;
}

.cds--btn--tertiary.background-color {
  background-color: var(--xpub-brand-color) !important;
}

.main-grid {
  position: fixed;
  left: 13rem;
  top: 3rem;
  background-color: #fdfffc;
  height: calc(100vh - 3rem);
  width: calc(100vw - 14rem);
  padding: 2rem 2rem 2rem 1rem;
  overflow-y: auto;
}

.styleTemplate .cds--modal-header .cds--modal-header__heading {
  padding-inline-end: 0 !important;
  justify-content: space-between;
  display: flex;
}

.styleTemplate .cds--modal-header {
  display: flex !important;
  align-items: center;
}

.cds--dropdown--open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.cds--dropdown--open .cds--list-box__field {
  border-block-end-color: var(--xpub-brand-color) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.cds--list-box__field:focus {
  outline-color: var(--xpub-brand-color) !important;
}

.cds--text-area:focus {
  outline-color: var(--xpub-brand-color) !important;
}

.cds--modal-footer .cds--btn--primary {
  border-radius: 0 0 var(--border-radius) 0 !important;
}

.cds--modal-footer .cds--btn--danger {
  border-radius: 0 0 var(--border-radius) 0 !important;
}

.cds--modal-footer .cds--btn--secondary {
  border-radius: 0 0 0 var(--border-radius) !important;
}

.cds--table-toolbar {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.cds--pagination {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.cds--toolbar-search-container-active {
  border-color: red;
}

.column-dashboard {
  height: calc(100vh - 4rem);
  border-top-left-radius: var(--border-radius);
  margin-right: 0% !important;
}

.cds--modal-close {
  background-color: transparent !important;
}

.cds--tabs .cds--tabs__nav-link:focus {
  outline: none !important;
}

sup[data-file-name] {
  position: relative; // Establish positioning context for ::after
  cursor: pointer;

  &::after {
    content: attr(data-file-name); // Fetch the file name
    position: absolute;
    font: inherit;
    bottom: 150%; // Position the tooltip above the element
    left: 50%;
    border: 1px solid #e0e0e0;
    padding: 0 10px;
    transform: translateX(-50%);
    background-color: white;
    color: #06c;
    padding: 5px 8px;
    white-space: nowrap;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
}

.cds--modal-content:focus {
  outline: none !important;
}

.page-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center;
}

.page-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.page-title {
  font-size: var(--text-extra-large);
}

.page-support-text-container {
  display: flex;
  padding: 1.3rem;
  align-items: center;
  width: 100%;
  font-size: var(--text-medium);
}

.login-links {
  font-size: var(--text-small);
}
