.account-info {
  margin-bottom: 20px;
}

.account-info p {
  margin-top: 5px;
  margin-bottom: 10px;
}

.info-column {
  margin-top: 2%;
}

.info-container {
  border: 0.5px solid lightgray;
  border-radius: var(--border-radius);
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-top: 20px;
  padding-right: 20px;
}

.cds--accordion__heading {
  box-shadow: none !important;
  padding: 0 !important;

  ::before {
    background-color: unset;
    content: none;
  }
}

.cds--accordion__heading:hover {
  background-color: unset;
}

.info-container .cds--accordion__title {
  font-weight: bold;
  font-size: var(--text-medium);
}
