.readiness-index {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 300px;
  
    &__loader {
      position: relative;
      width: 80px;
      height: 80px;
      flex-shrink: 0;
    }
  
    &__circle {
      width: 100%;
      height: 100%;
      transform: rotate(-90deg);
    }
  
    &__score {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      font-weight: 700;
      transition: color 0.3s ease;
    }
  
    &__text {
      margin-left: 15px;
    }
  
    &__title {
      font-size: 18px;
      font-weight: 500;
      color: #333;
      margin: 0 0 5px;
      line-height: 1.2;
    }
  
    &__scale {
      font-size: 14px;
      color: #666;
      margin: 0;
    }
  }