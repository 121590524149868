.cds--accordion__content {
  padding: 0 !important;
}

.inline-loading-file {
  .list-item .cds--inline-loading__text {
    color: var(--text-color-secondary);
  }
}

.clickable-list-item {
  flex-shrink: 1;
  display: block;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  padding: 0.5rem 2rem 0.5rem 0.5rem; // Added 1rem padding to the left
  background-color: transparent;

  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    // width: 100%;
    border-radius: var(
      --border-radius
    ); // Changes background color when hovering over the button
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
    // width: 100%;
    border-radius: var(--border-radius);
  }

  .list-item {
    display: flex;
    // align-items: center;
    width: 100%;

    .button-text {
      margin-left: 4px;
      min-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: var(--text-medium);
      color: var(--text-color-secondary);
      fill: var(--text-color-secondary);
    }

    .file-icon {
      flex-shrink: 0;
    }
  }

  .svg {
    fill: var(--text-color-secondary);
    width: 15px;
  }
}

.overflow-vert {
  height: 1.1rem;
  width: 1.1rem;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.file-list-header {
  margin-bottom: 1rem;
  padding: 1.25rem;
  //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.file-item-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  // margin-left: 0.5rem;
}

.menu-button {
  margin-left: 8%;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.menu-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  left: 0;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
}

.menu-options button {
  color: black;
  font-size: var(--text-medium);
  background: none;
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 8px 15px;
  cursor: pointer;
  text-align: left;
  justify-content: flex-start;
}

.menu-icons {
  // color: black !important;
  // fill: black !important;
  fill: var(--text-color-secondary) !important;
  width: 15px;
}

.overflow-menu-container {
  /* This might be a new div wrapper if needed */
  position: relative;
  z-index: 1000;
  /* High z-index to ensure menu is on top */
}

.context-menu {
  width: 200px;
  position: fixed;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 0.5rem 0;
  border-radius: 4px;
  border: 1px solid #ddd;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0.5rem 3rem 0.5rem 0.5rem;
      cursor: pointer;
      color: #333;
      font-size: var(--text-medium);

      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
}

.context-menu span {
  display: block;
  padding: 0.5rem 3rem 0.5rem 0.5rem;
  color: #333;
  font-size: var(--text-medium);
  font-weight: 600;
  width: 100%;
}

/* Additional helper classes */
.overflow-y-auto {
  overflow-y: auto;
}

.max-height-45vh {
  max-height: 45vh;
}

.no-files-text {
  padding: 0.5rem 0 0.5rem 1rem;
  font-size: var(--text-medium);
  color: #c2c2c2;
}
