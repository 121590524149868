// RFPLoader.scss

.rfp-loader {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: var(--text-color-secondary);
  padding: 3rem;

  // &__title {
  //   font-size: 1.5rem;
  //   margin-bottom: 4rem;
  // }

  &__progress-container {
    display: flex;
    justify-content: center;
    margin: 10rem 0 3rem 0;
  }

  &__progress-bars {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
  }

  &__progress-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;

    &-label {
      width: 12rem;
      font-size: 1rem;
      text-transform: capitalize;
    }

    &-bar {
      flex-grow: 1;
      height: 0.55rem;
      // background-color: #380a3d;  // Slightly lighter purple for the bar background
      background-color: lightgray;
      border-radius: 9999px;
      overflow: hidden;

      &-fill {
        height: 100%;
        background-color: white;
        background-color: var(--button-primary);
        border-radius: 9999px;
        transition: width 0.3s ease-out;
      }
    }
  }
}
