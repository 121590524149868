.tiles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.tile-wrapper {
  flex: 1 1 calc(33.3333% - 1rem);
}

@media (max-width: 1200px) {
  .tile-wrapper {
    flex: 1 1 calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  .tile-wrapper {
    flex: 1 1 100%;
  }
}

.modal-description-text {
  display: flex;
  margin: 1.3rem 0;
  align-items: center;
  width: 100%;
  font-size: 0.6rem;
}
