.inline-loader-editor {
  border: 1px solid var(--contrast-color-secondary);
  width: 100%;
  padding-left: 10px;
}

.custom-overflow-menu .cds--overflow-menu__icon {
  display: none !important;
}

.custom-overflow-menu .cds--overflow-menu__trigger button {
  display: none !important;
}

.cds--overflow-menu__trigger {
  display: none !important;
  background-color: pink !important;
}

.custom-trigger-button {
  display: inline-block;
  height: 2.5rem;
  width: 100%;
  cursor: pointer;
}

.magic-action {
  margin-right: 5px;
  /* Add some spacing between the text and the close button */
}

.editor-toolbar {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--contrast-color-secondary);
  border-top: 2px solid var(--contrast-color-secondary);
}

.close-button {
  font-weight: bold;
  color: #000000;
}

.cds--inline-notification {
  max-width: 100%;
  color: black;
}

.cds--inline-notification--error {
  background: white;
}

.ql-container.ql-snow {
  // height: calc(100vh - 9rem);
  background: rgb(212, 212, 212);
  // background: white;
  justify-content: center;
  flex: 1 0 auto;
  display: flex;
  overflow: hidden;
  border: none;
  font-size: inherit;
  // box-shadow: 20px 0 20px -10px lightgrey, -20px 0 20px -10px lightgrey;
  /* add this line */
  background-color: inherit;
  &.notification {
    height: calc(100vh - 100rem) !important;
  }
}

.ql-toolbar.ql-snow {
  // border-bottom: 1px solid #ebebeb;
  border: none;
  background: white !important;
  // box-shadow: -20px 0 20px -10px lightgrey;
}

.audioWord {
  cursor: pointer;
  // border: 0.1px solid #ebebeb;
  padding: 2px 0;
  border-radius: 0.2rem;
  background-color: #e0dede;
  // background-color: #aaa9a9;
}

.ql-editor {
  width: 100%;
  padding-left: 10% !important;
  padding-right: 10% !important;
  padding-top: 6% !important;
  padding-bottom: 6% !important;
  background: rgb(255, 255, 255);
  overflow-y: auto;
  position: relative;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0.5rem;
}

.ql-formats {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 8px;
}

.ql-formats button,
.ql-formats select {
  margin-right: 8px;
}

.ql-blank::before {
  padding-left: 7%;
  font-size: var(--text-medium);
}

/* CircularImage.css */
.circular-component {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #007bff;
  display: flex;
  justify-content: center;
  background-color: black;
}

.circular-component img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.custom-menu-container {
  position: relative;
  display: inline-block;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  /* Adjust the width as needed */
  padding: 8px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-bottom: none;
  /* Remove bottom border */
  border-radius: 4px 4px 0 0;
}

.menu-title {
  flex-grow: 1;
  /* Expand to fill available space */
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.close-button {
  font-weight: bold;
  color: #000;
  cursor: pointer;
}

.menu-items {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 0;
  box-sizing: border-box;
}

.action-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  cursor: pointer;

  &.disabled {
    color: lightgray;
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
      background-color: inherit;
      color: lightgray;
      cursor: not-allowed;
    }
  }

  &.submenu-item {
    background-color: f5f5f5;
    padding-left: 20px;
  }

  &:hover:not(.disabled) {
    background-color: #e0e0e0;
  }
}

#newspubLogo .cds--btn--icon-only.cds--btn {
  border-bottom-left-radius: 18px !important;
  border-bottom-right-radius: 18px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.icon_button {
  padding: 0.35rem 0 !important;
}

.export-tab-container {
  margin-right: 0.5rem;
  display: flex;
  border-radius: var(--border-radius);
  align-items: center;
  justify-items: center;
  gap: 1rem;
}

.export-tab-button {
  display: flex;
  color: var(--text-color-secondary);
  border: 1px solid var(--contrast-color-secondary);
  border-radius: var(--border-radius);
  padding: 0.2rem 0.5rem;
  background-color: #f3f3f3;
  width: 5rem;
  font-weight: 500;
}

@keyframes buttonPressAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}

.export-tab-button svg path {
  stroke: black;
  stroke-width: 0.02rem;
  fill: black;
}

.cds--modal-footer .cds--btn--primary {
  padding: 0.875rem;
}

.cds--modal-footer .cds--btn--secondary {
  padding: 0.875rem;
}

.cds--btn--primary {
  background-color: var(--xpub-accent-color-2);
  color: var(--text-color-primary);
  padding: 0.9rem 2rem;

  &:focus {
    border-color: var(--xpub-brand-color-border);
    box-shadow: inset 0 0 0 1px var(--xpub-brand-color-border),
      inset 0 0 0 2px #ffffff;
  }

  &:hover {
    background-color: var(--button-primary-hover);
  }
}

.cds--btn--secondary {
  padding: 0.9rem 2rem;
}

.cds--btn--tertiary {
  background-color: var(--xpub-accent-color-2);
  color: var(--text-color-primary);
  border-color: var(--xpub-accent-color-2);
  &:hover {
    background-color: var(--button-tertiary-hover);
  }

  &:focus {
    background-color: var(--button-tertiary-active);
    border-color: var(--xpub-accent-color-2);
    box-shadow: inset 0 0 0 1px var(--xpub-brand-color-border),
      inset 0 0 0 2px #ffffff;
  }
  &:active {
    background-color: var(--button-tertiary-active);
    border-color: var(--xpub-accent-color-2) !important;
  }
}

.cds--list-box__menu-item--highlighted {
  outline: 2px solid var(--xpub-brand-color-border);
}

.cds--text-input {
  &:focus {
    outline-color: var(--xpub-brand-color-border) !important;
  }
}

.cds--list-box__field:focus {
  outline-color: var(--xpub-brand-color) !important;
}

.cds--select-input {
  border-radius: 1rem !important;
  &:focus {
    outline-color: var(--xpub-brand-color-border) !important;
  }
}

.cds--dropdown--open .cds--list-box__field {
  border-block-end-color: var(--xpub-brand-color) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
